.observe {
    opacity: 0;
    transform: translateY(100px) !important;
}

$red: #bd2e2b;
$blue: #244064;

html {
    overflow-x: hidden;
}

.show {
    transition: all 1s;
    opacity: 1 !important;
    transform: translateY(0px) !important;
}
// h2{
//   text-align: center;
//   @media (max-width: 960px) {
//     font-size: 2rem;
//    }
// }
// h1{
//   text-align: center;
//   @media (max-width: 960px) {
//     font-size: 2rem;
//    }
// }

body {
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
}
.sub-heading {
    text-align: center;
    font-size: 1.7rem;
    @media (max-width: 600px) {
        font-size: 1.4rem;
    }
}
.top {
    padding-top: 10px;
    padding-bottom: 0;

    @media (max-width: 600px) {
        padding-top: 2px;
    }
}

.phoneone {
    @media (max-width: 600px) {
        display: none !important;
    }
}

.phonetwo {
    display: none !important;

    @media (max-width: 600px) {
        display: block !important;
    }

    @media (max-width: 400px) {
        display: fixed !important;
        bottom: 0 !important;
        right: 0 !important;
    }
}
#top {
    .content {
        width: 80%;
        a {
            width: 11rem;
        }
        p {
            width: 11rem;
        }
    }
}
#top .btn {
    border-radius: 15px;
    font-weight: 500;
    @media (max-width: 600px) {
        margin-top: -50px !important;
    }

    @media (max-width: 400px) {
        margin-top: 0 !important;
    }
}
#top .wrap {
    display: flex;
    align-items: center;
}
.button {
    padding: 15px 25px;
    border-radius: 15px;
    color: #fff;
    background-color: $red;
    display: inline-block;
    border: unset;
}
.button:hover {
    background-color: $blue;
    color: #fff;
    text-decoration: unset;
}
.top p i {
    color: $red;
}
.menu {
    background-color: #eee;
    z-index: 2;
    // height: fit-content;
}
.menu .logo {
    width: 23%;
}
.nav-item .btn-menu {
    display: none;
    @media (max-width: 600px) {
        display: block;
    }
}
.logo img {
    height: 80px;
}
.navbar-light .navbar-nav .nav-link {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}
.fixphone {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99vw;
    z-index: 999;
    bottom: 4px;
    @media (max-width: 400px) {
        bottom: 0 !important;
        right: 0 !important;
    }
    .button {
        margin-bottom: 2rem;
        margin-right: 1rem;
        @media (max-width: 400px) {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
        }
    }
    .BadgeLayout__Container-sc-7ux0pm-0 {
        margin-left: 3rem;
        @media (max-width: 960px) {
            .BadgeLayout__Container-sc-7ux0pm-0 {
                transform: scale(0.6);
                transform-origin: left bottom;
            }
        }
    }
}
.footer h2 {
    font-family: "Montserrat", sans-serif;
    color: #244064;
    font-size: 24px;
    font-weight: 900;
}
.carousel .item {
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../img/homeslider.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    padding: 100px 0;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #eee;
        opacity: 0.4;
        z-index: -1;
        transition: 0.5s;
    }
    h1 {
        font-family: "Montserrat", sans-serif;
        text-align: center;
        z-index: 10;
        span {
            color: $red;
            font-weight: 700;
        }
        @media (max-width: 960px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            font-size: 1.5rem;
            margin-top: 2rem;
        }
    }
}

.owl-carousel .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    h1 {
        max-width: 900px;
        span {
            color: $red;
            font-weight: 700;
        }
        @media (max-width: 960px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            font-size: 1.5rem;
            margin-top: 2rem;
        }
    }
}
.carousel .item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.navbar-active {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
}
// .owl-prev {
//   position: absolute;
//   left: 10px;
//   top: 50%;
//   transform: translateY(-50%);
//   z-index: 999;
//   width: 50px;
//   height: 50px;
//   text-align: center;
//   line-height: 50px;
//   color: #111;
//   border: 1px solid #111 !important;
//   border-radius: 50% !important;
//   @media (max-width: 500px) {
//     display: none !important;
//   }
// }
// .owl-next {
//   position: absolute;
//   right: 10px;
//   top: 50%;
//   transform: translateY(-50%);
//   z-index: 999;
//   width: 50px;
//   height: 50px;
//   text-align: center;
//   line-height: 50px;
//   color: #111;
//   border: 1px solid #111 !important;
//   border-radius: 50% !important;
//   @media (max-width: 500px) {
//     display: none !important;
//   }
// }
// .owl-theme .owl-nav [class*="owl-"]:hover {
//   background: $blue;
//   color: #fff;
//   text-decoration: none;
// }

.sliderh2 {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 700;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    color: #000;
    text-align: center;
}

section h2 {
    font-size: 42px;
    line-height: 52px;
    font-weight: 700;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 20px;
    color: #000;
}
section h2 span {
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    color: $red;
}
section {
    padding: 60px 0px;
}

.boxheader {
    color: #fff !important;
}

.boxp {
    color: #fff !important;
}

.yellowbg {
    background-color: #ffebb2 !important;
}

.yellowhover {
    &:hover {
        background-color: #ffebb2 !important;
        cursor: pointer;
    }
}

.pinkbg {
    background-color: #fcd9ec !important;
}

.pinkhover {
    &:hover {
        background-color: #fcd9ec !important;
        cursor: pointer;
    }
}

.greenbg {
    background-color: #c1e6c5 !important;
}

.greenhover {
    &:hover {
        background-color: #c1e6c5 !important;
        cursor: pointer;
    }
}

.bluebg {
    background-color: #e0e7ff !important;
}

.bluehover {
    &:hover {
        background-color: #e0e7ff !important;
        cursor: pointer;
    }
}

.yellowhover:hover .boxheader {
    color: #000 !important;
}

.yellowhover:hover .boxp {
    color: #000 !important;
}

.greenhover:hover .boxheader {
    color: #000 !important;
}

.greenhover:hover .boxp {
    color: #000 !important;
}

.bluehover:hover .boxheader {
    color: #000 !important;
}

.bluehover:hover .boxp {
    color: #000 !important;
}

.pinkhover:hover .boxheader {
    color: #000 !important;
}

.pinkhover:hover .boxp {
    color: #000 !important;
}

.box1 {
    position: relative;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
    padding: 10px 30px;
    height: 100%;
    border-radius: 10px;
    margin-right: 2rem;
    background-color: $blue;
    color: #fff;
    margin: 20px 0 !important;

    @media (max-width: 960px) {
        height: fit-content;
        margin-right: 0rem;
    }
    margin-bottom: 30px;
}
section h3 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #000;
}
section p,
section li {
    color: rgb(126, 126, 126);
    font-size: 15px;
    font-family: "Poppins", sans-serif;
}
.icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: $blue;
    text-align: center;
    line-height: 70px;
    margin-bottom: 30px;
}
.icon i {
    color: $blue !important;
    font-size: 30px;
}

.video {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 2px 24px rgba(0, 0, 0, 0.2);
    border: 0;
}
.box-img1 {
    width: 100%;
    max-width: 100%;
    height: 300px;
    margin-bottom: 30px;
}
.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
section.carousel {
    padding: 0px;
}
.bg-blue {
    background-color: $blue;
    text-align: center;
}
.bg-blue h2 {
    color: #fff;
}
.bg-white {
    background-color: #fff;
}
.phone {
    color: $red;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
}
.phone:hover {
    color: #fff;
    text-decoration: unset;
}
i.nbr {
    font-size: 50px;
    color: #fff;
}
.box-img2 {
    height: 300px;
    margin-bottom: 30px;
    position: relative;
}
.box-img2::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}
.fixed-img {
    background-image: url(../img/Posecpompe.jpeg);
    background-size: cover;
    background-position: center bottom;
    position: relative;
    z-index: -2;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ccc;
        opacity: 0.5;
        z-index: -3;
        transition: 0.5s;
    }
}

.fixedbox {
    height: 15rem !important;

    @media (max-width: 990px) {
        height: 100% !important;
    }
}

.whitebox {
    background-color: rgb(245, 245, 245) !important;

    &:hover {
        background-color: $blue !important;
        cursor: pointer;
    }
}

.whitebox:hover h3 {
    color: #fff !important;
}

.whitebox:hover p {
    color: #fff !important;
}

.icon1 i {
    font-size: 60px;
    color: #111;
}
.avis p {
    color: #fff;
}
.posr {
    position: relative;
}
.center-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.pad30 {
    padding: 60px 0px;
}
section.body {
    padding: 0px;
}
.noPadding {
    padding: 0px;
}
section.slider {
    padding: 170px 0px;
    background-size: cover;
    background-position: center;
    position: relative;
    position: relative;
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #131313;
        opacity: 0.2;
        z-index: -1;
        transition: 0.5s;
    }
}
section.slider h1 {
    color: #000 !important;
    font-size: 50px;
    text-align: center;
    text-transform: uppercase;

    span {
        color: $red;
        font-size: 50px;
        font-weight: 600;
        @media (max-width: 960px) {
            font-size: 2rem;
        }
    }
    @media (max-width: 960px) {
        font-size: 2rem;
    }
}
section.slide1 {
    background-image: url(../img/slider1.jpg);
    background-size: cover;
}

.realisations {
    background-image: url(../img/realisations.jpeg);
    background-size: cover;
}
// ul.list
// {
//   padding: 0px;
//   margin: 0px;
// }
ul.list li {
    list-style: none;
    position: relative;
}
ul.list li::before {
    content: "\f141";
    font-family: flaticon !important;
    position: absolute;
    left: -25px;
    color: $red;
}
.form-control {
    border-radius: 0;
    border: none;
    background-color: #eee;
}
.call-number a {
    font-size: 50px;
    color: $blue;
}
.call-number a:hover {
    color: $red;
    text-decoration: unset;
}
.infos p i {
    color: $blue;
    font-size: 50px;
    position: relative;
    top: 7px;
}
.infos p {
    font-size: 22px;
}

.footer {
    background: url(../img/footerbg.png);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 190px;

    &_content {
        font-family: "Montserrat", sans-serif;

        &-phone {
            font-family: "Montserrat", sans-serif;
            color: $red;
            font-size: 30px;
            font-weight: 600;
        }

        &-icon {
            color: $red;
            font-size: 27px;
            margin-right: 1rem;
        }

        &-p {
            display: flex;
            align-items: center;
            font-family: "Montserrat", sans-serif;
            letter-spacing: 0.05em;
            font-size: 16px;
            text-align: justify;
            font-weight: 600;
        }

        &-logo {
            width: 30%;
            max-width: 30%;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }

        &-logo2 {
            width: 15%;
            max-width: 15%;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }

        &-ul {
            list-style: none;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-link {
            margin-bottom: 0.2rem;
            text-align: center !important;
            font-weight: 600;

            &:hover {
                text-decoration: none;
                cursor: pointer;
            }
        }

        &-img {
            width: 10rem;
            max-width: 10rem;
        }

        &-img2 {
            width: 100%;
            max-width: 100%;
        }

        &-social {
            background-color: $blue;
            border-radius: 50%;

            &--icon {
                font-size: 30px;
                color: #fff;
                padding: 10px;
                margin: 0 5px;
                text-align: center;
            }
        }
    }
}

.gallery {
    margin-bottom: 25px;

    &-img {
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 10px;
    }
}

.nav-top {
    .container {
        .row {
            .btn-wrap {
                background-color: red;
                .btn {
                    background-color: red;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .BadgeLayout__Container-sc-7ux0pm-0 {
        transform: scale(0.6);
        transform-origin: left bottom;
    }
}

@media (max-width: 400px) {
    .BadgeLayout__Container-sc-7ux0pm-0 {
        display: none;
    }
}

// Home
#one {
    p {
        font-size: 13px;
        max-width: 1000px;
    }
    img {
        width: 2.6rem;
        color: $blue !important;
    }
}

#two img {
    width: 35rem;
    height: 35rem;
    object-fit: cover;
    margin-left: 6rem;
    @media (max-width: 1100px) {
        margin-left: 2rem;
        width: 25rem;
        height: 25rem;
    }
    @media (max-width: 460px) {
        margin-left: 0rem;
        width: 20rem;
        height: 20rem;
    }
}
#three h2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
#three h2 {
    padding: 0;
    font-size: 1.5rem;
}
#four {
    .col-sm-4 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        height: 100%;
        width: 95%;
        margin-bottom: 3rem;
    }
}
#five {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        text-align: center;
        color: #bd2e2b;
        padding: 0.1rem;
    }
    p {
        text-align: center;
        padding: 0.1rem;
    }
    span {
        font-weight: 700;
    }
    .btn {
        border-radius: 15px;
        margin-bottom: 1.5rem;
    }
    img {
        width: 13rem;
        object-fit: cover;
    }
}

.height-10 {
    height: 30rem !important;
}

#six {
    .wrap {
        margin-top: 4rem;
        @media (max-width: 960px) {
            margin-top: 0rem;
        }
    }
    img {
        width: 100%;
        max-width: 100%;
        border-radius: 10%;

        @media (max-width: 960px) {
            display: none;
        }
    }
    .box1 {
        width: 100%;
        height: 100%;
        padding: 1rem 2rem;

        img {
            width: 2rem;
        }
    }
    .wrap {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: -5rem;
        @media (max-width: 960px) {
            margin-left: 0rem;
            width: 100%;
        }
        p {
            font-size: 0.8rem;
        }
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5rem;
            height: 3.5rem;
            margin-bottom: 0.4rem;
        }
        h3 {
            color: #244064;
            font-size: 1.1rem;
        }
        .four {
            background-color: rgb(241, 187, 86);
        }
        .one {
            background-color: rgb(166, 166, 226);
        }
        .two {
            background-color: greenyellow;
        }
        .three {
            background-color: pink;
        }
    }
}
#seven {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../img/maison.jpeg);
    background-size: cover;
    background-position: center;
    h2 {
        text-align: center;
        color: white;
        padding: 0.1rem;
    }
    p {
        text-align: center;
        padding: 0.1rem;
        color: white;
    }
    span {
        font-weight: 700;
    }
    .btn {
        border-radius: 15px;
        margin-bottom: 1.5rem;
    }
}
#eight {
    display: flex;
    align-items: flex-start;

    @media (max-width: 960px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .text-wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 960px) {
            width: 100%;
            align-items: center;
            justify-content: center;
        }
        p {
            margin-left: 10rem;
            @media (max-width: 960px) {
                margin-left: 0rem;
            }
        }
        .bold {
            font-weight: 900;
            color: $red;
            text-transform: uppercase;
        }
        .para {
            font-weight: 500;
        }
    }
    img {
        width: 20rem;
        margin-left: 10rem;
        margin-top: -5rem;

        @media (max-width: 1098px) {
            width: 32rem;
        }
        @media (max-width: 960px) {
            width: 21rem;
        }
    }
    .div {
        height: 30vh;
        width: 3rem;
        background-color: $red;
        margin-top: 4rem;
        @media (max-width: 960px) {
            display: none;
        }
    }
}
#nine {
    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
            text-align: center;
        }
    }
    .fixed-img {
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            font-weight: 500;
            @media (max-width: 960px) {
                font-size: 1.4rem;
                color: white;
            }
        }
    }

    .posr {
        background-color: $red;
    }
    img {
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
    }
    h4 {
        color: white;
    }
}
#ten {
    h2 {
        margin-left: 5rem;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            left: -2rem;
            top: 0;
            background-color: $red;
            height: 4rem;
            width: 1rem;
        }
        @media (max-width: 960px) {
            font-size: 2rem;
            margin-left: 1rem;
        }
    }
    p {
        margin-left: 3rem;
        max-width: 1000px;
        padding: 0.1rem;
        @media (max-width: 960px) {
            margin-left: 1rem;
        }
        span {
            font-weight: 600;
            color: black;
        }
    }
}
.about-one {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin-top: 3rem;
    .img-wrap {
        width: 50%;
        @media (max-width: 960px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .img-index {
            z-index: -2;
        }
        img {
            width: 26rem;
            height: 23rem;
            object-fit: cover;
            margin-left: 3rem;
            box-shadow: -2px 2px 24px 4px rgba(0, 0, 0, 0.38);
            -webkit-box-shadow: -2px 2px 24px 4px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: -2px 2px 24px 4px rgba(0, 0, 0, 0.38);
            @media (max-width: 960px) {
                width: 20rem;
                height: 18rem;
                margin-left: 0rem;
                margin-bottom: 2rem;
            }
        }
    }
    .neg {
        margin-top: -1.2rem;
        z-index: -2;
    }
    .index {
        z-index: -3;
    }
    h2 {
        font-size: 1.5rem;
        margin: 0;
        line-height: 1.5;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -0.15rem;
            height: 3px;
            width: 20%;
            background-color: $red;
        }
    }
    h1 {
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 4rem;
        color: #244064;
    }
    .content-wrap {
        display: flex;
        align-items: flex-start;
        @media (max-width: 960px) {
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .text-wrap {
            width: 50%;
            margin-left: -4.5rem;
            @media (max-width: 1020px) {
                margin-left: 0;
                width: 85%;
            }
            p {
                max-width: 800px;
                text-align: justify;
                margin-top: 0.5rem;
                @media (max-width: 1020px) {
                    max-width: 600px;
                }
                @media (max-width: 960px) {
                    margin-bottom: 3rem;
                    max-width: 700px;
                }
            }
        }
    }
}
.about-two {
    width: 100vw;
    display: flex;
    margin-top: 4rem;
    @media (max-width: 960px) {
        margin-bottom: 10rem;
    }
    .content-wrap {
        display: flex;
        align-items: flex-start;
        width: 100%;
        @media (max-width: 960px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .text-wrap {
            width: 50%;
            display: flex;
            flex-direction: column;
            @media (max-width: 960px) {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            h2 {
                font-size: 1.5rem;
                margin: 0;
                position: relative;
                margin-left: 5rem;
                line-height: 1.5;
                @media (max-width: 960px) {
                    margin-left: 0rem;
                }
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: -0.15rem;
                    height: 3px;
                    width: 20%;
                    background-color: $red;
                }
            }
            p {
                max-width: 500px;
                text-align: justify;
                margin-left: 5rem;
                margin-top: 0.5rem;
                @media (max-width: 1020px) {
                    max-width: 600px;
                }
                @media (max-width: 960px) {
                    max-width: 700px;
                    margin-left: 0rem;
                }
            }
            .image-wrap {
                display: flex;
                align-items: center;
                margin-left: 5rem;
                @media (max-width: 960px) {
                    margin-left: 0rem;
                }
                .logo1 {
                    width: 7rem;
                    margin-right: 2rem;
                    margin: 2rem 0;
                }
                @media (max-width: 960px) {
                    margin-bottom: 20rem;
                }
            }
        }
        .img-wrap {
            width: 45%;
            position: relative;
            margin-left: 1rem;
            @media (max-width: 1216px) {
                margin-left: 4rem;
            }
            @media (max-width: 960px) {
                width: 70%;
                margin-left: 0rem;
            }
            img {
                width: 20rem;
                height: 30rem;
                object-fit: cover;
                margin-left: 3rem;
                @media (max-width: 960px) {
                    width: 15rem;
                }
            }
            .div {
                position: absolute;
                height: 20rem;
                width: 15rem;
                background-color: $blue;
                left: -2rem;
                bottom: -6rem;
                z-index: -1;
                @media (max-width: 960px) {
                    width: 10rem;
                }
            }
            .content {
                position: absolute;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                height: 20rem;
                width: 19rem;
                background-color: $blue;
                right: 10rem;
                top: -17rem;
                z-index: -1;
                background-color: rgb(214, 212, 212);
                p {
                    max-width: 400px;
                    margin-top: 3rem;
                    padding: 1.4rem;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        left: 1.3rem;
                        top: -0.15rem;
                        height: 3px;
                        width: 20%;
                        background-color: $red;
                    }
                    @media (max-width: 960px) {
                        font-size: 0.8rem;
                    }
                }
                @media (max-width: 960px) {
                    right: 1rem;
                    width: 15rem;
                }
            }
        }
    }
}
.about-three {
    display: flex;
    width: 100vw;
    margin-top: 2rem;
    .content-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        position: relative;
        &::before {
            position: absolute;
            content: "CHIFFRES CLÉS";
            font-size: 8.2rem;
            font-weight: 900;
            letter-spacing: 16px;
            z-index: -1;
            color: rgba(0, 0, 0, 0.03);
            @media (max-width: 1245px) {
                font-size: 7rem;
            }
            @media (max-width: 1009px) {
                display: none;
            }
        }
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .num {
                font-size: 4rem;
                font-weight: 900;
            }
            p {
                text-transform: uppercase;
                letter-spacing: -0.8px;
                font-weight: 500;
                color: $red;
                margin-top: -1rem;
            }
        }
    }
}
.about-four {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
    background: linear-gradient(to top, rgb(36, 64, 100), rgb(255, 255, 255));
    h2 {
        margin-top: 2rem;
        position: relative;
        text-align: center;
        &::before {
            position: absolute;
            content: "";
            left: 35%;
            top: -0.15rem;
            height: 3px;
            width: 30%;
            background-color: $red;
        }
    }
    .content-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .content {
            display: flex;
            flex-direction: column;
            width: 35rem;
            height: 25rem;
            background: rgba(255, 255, 255, 0.4);
            margin: 0 1rem;
            margin-bottom: 1rem;
            padding: 3rem 1.5rem;
            @media (max-width: 960px) {
                height: 29rem;
            }
            .text-wrap {
                display: flex;
                align-items: center;
                .num {
                    font-size: 4rem;
                    margin-right: 1rem;
                    color: $red;
                }
                h3 {
                    color: #244064;
                    max-width: 250px;
                    font-size: 1.1rem;
                }
            }
            p {
                color: black;
            }
        }
    }
}
.about-five {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
    p {
        text-align: center;
        max-width: 900px;
    }
    img {
        margin-bottom: 20px;
    }
}

// climate
.sec-one {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
    &::before {
        position: absolute;
        content: "";
        bottom: -2rem;
        width: 100%;
        height: 50vh;
        background: $blue;
        z-index: -1;
    }
    h2 {
        text-align: center;
        margin-top: 2rem;
        @media (max-width: 960px) {
            font-size: 2rem;
        }
        @media (max-width: 400px) {
            font-size: 1.7rem;
        }
    }
    h3 {
        color: #244064;
        text-align: center;
        @media (max-width: 960px) {
            font-size: 2rem;
        }
    }
    .content-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18rem;
            height: 25rem;
            background: white;
            box-shadow: -1px 1px 2px 2px rgba(70, 15, 15, 0.1);
            margin: 0 1rem;
            @media (max-width: 960px) {
                margin-bottom: 2rem;
            }
            .wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .logo-wrap {
                    width: 3.6rem;
                    height: 3.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background: #c8d8ec;
                    margin-bottom: 1rem;
                    img {
                        width: 2rem;
                    }
                }
                p {
                    text-align: center;
                    font-size: 0.9rem;
                    max-width: 250px;
                }
            }
        }
    }
}

.content-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18rem;
        height: 25rem;
        background: white;
        box-shadow: -2px 2px 4px 4px rgba(0, 0, 0, 0.2);
        margin: 0 1rem;
        .wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .logo-wrap {
                width: 3.6rem;
                height: 3.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: #c8d8ec;
                margin-bottom: 1rem;
                img {
                    width: 2rem;
                }
            }
            p {
                text-align: center;
                font-size: 0.8rem;
                max-width: 250px;
            }
        }
    }
    a {
        border-radius: 10px;
        margin-top: 4rem;
        @media (max-width: 960px) {
            margin-bottom: 2rem;
            display: none;
        }
    }
}

.sec-two {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin-top: 5rem;
    h2 {
        text-align: center;
        @media (max-width: 960px) {
            font-size: 2rem;
        }
    }
    .content-wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        p {
            max-width: 500px;
            text-align: justify;
            margin: 1rem 1rem;
        }
    }
}

.sec-three {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 3rem;
    img {
        width: 25vw;
        height: 40vh;
        object-fit: cover;
        @media (max-width: 960px) {
            width: 50vw;
        }
        @media (max-width: 400px) {
            width: 100vw;
        }
    }
}

.sec-four {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    .content-wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        .left {
            width: 100%;
            .wrap {
                margin-left: 4rem;
                @media (max-width: 960px) {
                    margin-left: 0;
                }
                a {
                    border-radius: 10px;
                    align-self: center;
                }
            }
        }
        .right {
            width: 100%;
            display: flex;
            justify-content: center;
            .wrap {
                background: $red;
                color: white;
                width: 25rem;
                padding: 1rem;
                h2 {
                    font-size: 2rem;
                    font-weight: 300;
                }
                .text {
                    margin-bottom: 1rem;
                    font-size: 0.8rem;
                    color: rgba(255, 255, 255, 0.7);
                }
                p {
                    color: white;
                    font-weight: 200;
                    i {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.sec-five {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin-top: 6rem;
    h2 {
        text-align: center;
        width: 100%;
        margin-bottom: 3rem;
    }
    .content-wrap {
        width: 100%;

        .left {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 960px) {
                width: 80%;
                margin-bottom: 2rem;
            }
            .wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h3 {
                    color: #244064;
                    text-align: center;
                }
                p {
                    max-width: 300px;
                    text-align: center;
                }
            }
        }
        .center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 960px) {
                width: 80%;
                margin-bottom: 2rem;
            }
            .wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h3 {
                    color: #244064;
                    text-align: center;
                }
                p {
                    max-width: 300px;
                    text-align: center;
                }
                .img-wrap {
                    img {
                        margin-right: 2rem;
                        width: 6rem;
                    }
                }
            }
        }
        .right {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 960px) {
                width: 80%;
                margin-bottom: 2rem;
            }
            .wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h3 {
                    color: #244064;
                    text-align: center;
                }
                p {
                    max-width: 300px;
                    text-align: center;
                }
            }
        }
    }
}

.th-one {
    h3 {
        color: #244064;
        text-align: center;
        font-size: 1.2rem;
    }
}

#pomp-one {
    h2 {
        margin-top: 3rem;
    }
    .content {
        width: 35rem;
        height: 32rem;
        margin-bottom: 2rem;
        @media (max-width: 1185px) {
            height: 35rem;
        }
        @media (max-width: 400px) {
            height: 38rem;
            padding: 0.5rem;
        }
        p {
            max-width: 500px;
        }
    }
}

#pomp-four {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    margin-bottom: 2rem;
    max-width: 900px;
    text-align: center;
  }
  &:not(.no-phone){
  .left {
    .wrap{
      margin: 0px !important
    }
    position: relative;
    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 300px;
            height: 300px;
            background: #f8eaea;
            border-radius: 50%;
            content: "";
            z-index: -2;
            transform: translate(-50%, -50%);
        }
        &::before {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 60px;
            height: 60px;
            background: url("/assets/img/phone.svg");
            background-size: 40px 40px;
            background-repeat: no-repeat;
            opacity: 0.15;
            background-position: center;
            border-radius: 50%;
            content: "";
            z-index: -1;
            transform: translate(-50%, -50%);
        }
    }
  }
}

#main-two {
    h3 {
        text-align: center;
    }
}

#notrebg {
    background: url(../img/pompe-chaleur.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ccc;
        opacity: 0.4;
        z-index: -3;
        transition: 0.5s;
    }
}

#clibg {
    background: url(../img/PAC.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #eee;
        opacity: 0.3;
        z-index: -3;
        transition: 0.5s;
    }
}
#thbg {
    background: url(../img/maison.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #eee;
        opacity: 0.3;
        z-index: -3;
        transition: 0.5s;
    }
}
#pmbg {
    background: url(../img/pompe_a_chaleur_bg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #eee;
        opacity: 0.2;
        z-index: -5;
        transition: 0.5s;
    }
}
#mnbg {
    background: url(../img/mnbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -2;
}
#devisbg {
    background: url(../img/devisbg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: -2;
}
#contactbg {
    background: url(../img/contactbg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: -2;
}
#nosbg {
    z-index: -2;
}

#contact {
    i {
        font-size: 1.6rem;
    }
    h2 {
        text-align: center;
        margin-bottom: 2rem;
        @media (max-width: 960px) {
            font-size: 2rem;
        }
    }
    .checkbox {
        width: 45vw;
        margin-bottom: 2rem;
        .text {
            font-size: 0.9rem;
            @media (max-width: 960px) {
                text-align: center;
                max-width: 100px;
                font-size: 0.7rem;
            }
        }
        @media (max-width: 960px) {
            width: 90vw;
            margin-bottom: 1rem;
        }
    }
}

#foot {
    width: 100vw;
    img {
        width: 5rem;
        margin: 1rem 0rem;
    }
    p {
        max-width: 250px;
    }
    .foot-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;
        @media (max-width: 960px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 2rem;
            i {
                color: $red;
            }
            .image-wrap {
                display: flex;
                align-items: center;
                a {
                    img {
                        width: 2rem;
                        margin-right: 1rem;
                    }
                }
            }
        }
        .contents {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 2rem;
            @media (max-width: 960px) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
            .wrap {
                display: flex;
                flex-direction: column;
                margin: 0 1rem;
                a {
                    color: black;
                }
                @media (max-width: 960px) {
                    margin: 0rem;
                }
            }
        }
        .img-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 1rem;
            .wrap {
                display: flex;
                align-items: center;
                img {
                    width: 5rem;
                    margin: 1rem 1rem;
                }
            }
        }
    }
}

// Imports
@import "bootstrap";

.dropdown.active {
    a.dropdown-item {
        color: $body-color !important;
        &.active {
            color: $white !important;
        }
    }
}

#flex-content-wrap {
    flex-direction: row;
    align-items: center;
    padding: 0px 10vw;
    .left {
        h2 {
            font-size: 34px;
        }
        width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2.5vw;
        p {
            margin-bottom: -1px;
        }
    }
    .right {
        width: 20vw;
        margin-left: 2.5vw;
        h2 {
            font-size: 20px;
            line-height: 24px;
            color: white;
            font-weight: 300;
        }
        p{
            font-weight: 200;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        .left,
        .right {
            width: 80vw !important;
        }
    }
}

#flex-wrap-2 {
    flex-direction: row;
    align-items: center;
    .left{
      width: 60%
    }
    .right{
      width: 40%
    }
}



.footer_banner {
  background-color: var(--color-secondary);
  padding: 0 10rem
}

@media only screen and (max-width: 990px) {
  .footer_banner {
    padding: 2rem 5rem
  }
}

@media only screen and (max-width: 768px) {
  .footer_banner {
    padding: 2rem 1rem
  }
}

.footer_banner-ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem
}

.footer_banner-li {
  color: $blue;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  display: flex;
  align-items: center;
  margin-top: .5rem
}

@media only screen and (max-width: 990px) {
  .footer_banner-li {
    display: inline-block
  }
}

.footer_banner-li--link {
  color: $blue;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  white-space: nowrap !important
}

@media only screen and (max-width: 990px) {
  .footer_banner-li--link {
    white-space: inherit
  }
}

.footer_banner-li--link:hover {
  text-decoration: none;
  color: var(--color-primary);
  cursor: pointer
}
